body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Mulish',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'Mulish', sans-serif;
}


/* Social Icon Css */

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width:32px;
  height:32px;
}

.canvasProfile{
  width: 420px !important;
  Height: 150px !important;

}

.user{
  position: relative;
 }

 .profile img{

  
  height: 80px;
  width: 80px;
  margin-top:2px;

 
 }

 .profile{

  position: absolute;
  top:-50px;
  left: 38%;
  height: 90px;
  width: 90px;
  border:3px solid #fff;

  border-radius: 50%;

 }